import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-green-card-covid19',
  templateUrl: './green-card-covid19.component.html',
  styleUrls: ['./green-card-covid19.component.css']
})
export class GreenCardCOVID19Component implements OnInit {
  title : string = 'COVID 19 - USA Immigration, Re-entry and Green Card Related Emergency Services | ESMSYS';

  constructor(private router : Router, private titleService: Title,
    private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({
      name: 'tags', 
      content: 'COVID 19 - USA Immigration, Re-entry and Green Card Related Emergency Services | ESMSYS'
    });
    this.meta.addTag({
      name: 'description', 
      content: 'Worried for your USA Green Card or Re-entry permit during the India COVID 19 lockdown? Speak with US Immigration expert in India. Get support and consultation over phone, email and online video call. Conveniently Extend validity for your expiring Re-entry US permit. Green Card renewal or replacement and much more.'
    });
  }
  

  Onbtnclick(id: number){
    this.router.navigate(['us-green-card-c19/apply', id])
  }

}
