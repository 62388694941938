export class ValuationReport {
    constructor(
      public fname: string,
      public lname: string,
      public email: string,
      public address: string,
      public phone: string,
      public requestdetail: string,
      public id?:  number) {}
  }
  