import { Component, OnInit } from '@angular/core';
import { NVC } from './NVC';
import { NVCService } from './NVC.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-nvc-services',
  templateUrl: './nvc-services.component.html',
  styleUrls: ['./nvc-services.component.css']
})
export class NvcServicesComponent implements OnInit {
  nvcs: NVC[];
  error = '';
  alert_box: boolean = false;
  buttonStatus: boolean = true;
  myRecaptcha: boolean;
  nvc = new NVC('', '', '', '', '', '', '', '');

  constructor(private nvcService: NVCService, private http: HttpClient) {
  }

  ngOnInit() {

  }

  addCar(f: NgForm) {
    this.alert_box = f.invalid;
    this.resetErrors();

    for (var key in f.controls) {
      f.controls[key].markAsTouched();
    }

    if (!f.invalid) {

      this.nvcService.store(this.nvc)
        .subscribe(
          (res: NVC[]) => {

            // Update the list of cars
            this.nvcs = res;

            f.reset();
            this.buttonStatus = false;
          },
          (err) => this.error = err
        );
    }

  }


  private resetErrors() {
    this.error = '';
  }

  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)){
      return false;
    }
    return true;
  }
  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA');
  }
}
