import { Component, OnInit } from '@angular/core';
import { EB5GreenCardService } from './eb5-green-card.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-eb5-green-card',
  templateUrl: './eb5-green-card.component.html',
  styleUrls: ['./eb5-green-card.component.css']
})
export class Eb5GreenCardComponent implements OnInit {
  username: any='';
  email: any='';
  phone: any='';
  submitted: boolean;
  error: boolean = false;
  constructor(
    private EB5Service: EB5GreenCardService,
    private router: Router 

  ) { }

  ngOnInit() {
  }

  sendmail(e) {
    this.submitted = true;
    this.username= this.username.trim();
    this.email= this.email.trim();
    this.phone= this.phone.trim();
    if(e == false || this.username == '' || this.email == '') {
      return;
    } 
    const data= {
        "name" : this.username,
        "phone" : this.phone,
        "email" : this.email,
      }
      this.EB5Service.sendmail(data).subscribe((res: any) => {
        this.error = false;
        this.router.navigate(['/EB5/green-card-appointment'], { queryParams: { name: this.username, phone: this.phone, email: this.email} });
      },(error: any) => {   
        console.log(error.error_code); 
        this.error = true;
        
      });  
  }
}
