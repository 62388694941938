import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-thermalinsulation',
  templateUrl: './thermalinsulation.component.html',
  styleUrls: ['./thermalinsulation.component.css']
})
export class ThermalinsulationComponent implements OnInit {

  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {

    this.meta.addTag({
      name: 'description',
      content: 'We help organizations to improve their performance, operating primarily through the analysis of existing heat loss problems and the development of plans for improvement. '
    });
  }

}
