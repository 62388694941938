import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-covid19-form',
  templateUrl: './covid19-form.component.html',
  styleUrls: ['./covid19-form.component.css']
})
export class Covid19FormComponent implements OnInit {
  @Input() radioSelected;
  @Output() afterformSubmit = new EventEmitter<boolean>();
  covidForm: FormGroup;
  myRecaptcha:boolean;
  serviceArray = ['Renew my Green Card', 'Green Card is Lost',
                  'Extend U.S. Re-entry Travel Permit validity', 'Apply for a Green Card',
                  'NVC Interview rescheduling', 
                  'US Visa for emergency Travel', 'Medical','Other', ];
                  // 'US Visa for Covid 19 Health Specialist',
  helpingArray = ['I am applying for myself', 'I am helping a friend or a family member'];

  selectedserviceArray = [];
  selectedhelpingArray = [];

  baseUrl: string;

  constructor(private _fb: FormBuilder, private authService: AuthService, private http: HttpClient, private router : Router, private activatedRoute: ActivatedRoute) { 
    this.baseUrl = authService.getUrl();
  }

  ngOnInit() {
    this.covidForm = new FormGroup({
      'firstname' : new FormControl(null, Validators.required),
      'lastname' : new FormControl(null, Validators.required),
      'email' : new FormControl(null, [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      'mobileno' : new FormControl(null, Validators.required),
      'city' : new FormControl(null, Validators.required),
      'Age': new FormControl(null, [Validators.required, Validators.max(100), Validators.min(18)]),
      'helping': new FormControl('I am applying for myself'),
      'service': new FormControl(this.serviceArray[this.radioSelected]),
      'Message': new FormControl(null),
      'myRecaptcha': new FormControl(0)
    })
  }

  addserviceControls(){
    const arr = this.serviceArray.map(element => {
      return this._fb.control(false);
    })

    return this._fb.array(arr);
  }

  addhelpingControls(){
    const arr = this.helpingArray.map(element => {
      return this._fb.control(false);
    })

    return this._fb.array(arr);
  }

  onserviceChange(){
    this.selectedserviceArray = [];

    this.myserviceArray.controls.forEach((control, i) => {
      if(control.value){
        this.selectedserviceArray.push(this.serviceArray[i])
      }
    })
  }

  onhelpingChange(){
    this.selectedhelpingArray = [];

    this.myhelpingArray.controls.forEach((control, i) => {
      if(control.value){
        this.selectedhelpingArray.push(this.helpingArray[i])
      }
    })
  }

  get myserviceArray(){
    return <FormArray>this.covidForm.get('service')
  }

  get myhelpingArray(){
    return <FormArray>this.covidForm.get('helping')
  }

  submit(){
    var body = {
      name: this.covidForm.value.firstname + ' ' + this.covidForm.value.lastname,
      email: this.covidForm.value.email,
      mobileno: this.covidForm.value.mobileno,
      city_state: this.covidForm.value.city,
      age: this.covidForm.value.Age,
      helping: this.covidForm.value.helping,
      service: this.covidForm.value.service,
      message: this.covidForm.value.Message ? this.covidForm.value.Message : ''
    }
    
    
    this.http.post(`${this.baseUrl}/Covid19green`, body).subscribe((data) => {
      this.router.navigate(['/us-green-card-c19/Thankyou'])
      
    }, (error) => {
      this.afterformSubmit.emit(false);
    })
  }

  onBack(){
    if(this.activatedRoute.snapshot.url[0].path === "us-pr"){
      this.router.navigate(['us-pr'])
    }else{
      this.router.navigate(['us-green-card-c19'])
    }
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA');
  }



  hasError(e){
    if(this.covidForm.controls.mobileno.touched){
      e = !e;
      this.covidForm.controls.mobileno.setErrors(e);
    }
  }

  getNumber(e){
    this.covidForm.controls.mobileno.setValue(e);
  }

  telInputObject(e){
    
  }
  

  onCountryChange(e){
    
  }
}
