import { Component, OnInit } from '@angular/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { Car } from './car';
import { CarService } from './car.service';

@Component({
  selector: 'app-root',
  templateUrl: './p1.component.html',
  styleUrls: ['./p1.component.css']
})
export class P1Component implements OnInit {
  cars: Car[];
  error = '';
  success = '';
  public loading = true;

  car = new Car('', '');

  constructor(private carService: CarService) {
  }

  ngOnInit() {
    this.getCars();
  }

  getCars(): void {
    setTimeout(() => {
      this.carService.getAll().subscribe(
        (res: Car[]) => {
          this.loading = false;
          this.cars = res;
        },
        (err) => {
          this.loading = false;
          this.error = err;
        })
    }, 1000);
  }

  addCar(f) {
    this.resetErrors();

    this.carService.store(this.car)
      .subscribe(
        (res: Car[]) => {
          // Update the list of cars
          this.cars = res;

          // Inform the user
          this.success = 'Created successfully';

          // Reset the form
          f.reset();
          this.loading = true;
          this.getCars();
        },
        (err) => this.error = err
      );
  }

  deleteCar(id) {
    this.resetErrors();

    this.carService.delete(+id)
      .subscribe(
        (res: Car[]) => {
          this.cars = res;
          this.success = 'Deleted successfully';
          this.loading = true;
          this.getCars();
        },
        (err) => this.error = err
      );
  }

  private resetErrors() {
    this.success = '';
    this.error = '';
  }

}
