import { Component, OnInit } from '@angular/core';
import { Quote } from './Quote';
import { QuoteService } from './Quote.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-it-services',
  templateUrl: './it-services.component.html',
  styleUrls: ['./it-services.component.css']
})
export class ItServicesComponent implements OnInit {
  quote: Quote[];
  error = '';
  alert_box: boolean = false;
  buttonStatus: boolean = true;
  myRecaptcha: boolean;
  quo = new Quote('', '', '', '', '', '', '', '');

  constructor(private quoteService: QuoteService, private http: HttpClient) {
  }

  ngOnInit() {

  }

  addCar(f: NgForm) {
    this.alert_box = f.invalid;
    this.resetErrors();

    for (var key in f.controls) {
      f.controls[key].markAsTouched();
    }

    // window.location.href = 'itservice/brand/thank-you';
    if (!f.invalid) {

      this.quoteService.store(this.quo)
        .subscribe(
          (res: Quote[]) => {

            // Update the list of cars
            this.quote = res;

            f.reset();
            this.buttonStatus = false;
          },
          (err) => this.error = err
        );
    }

  }


  private resetErrors() {
    this.error = '';
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA');
  }
  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)){
      return false;
    }
    return true;
  }
}
