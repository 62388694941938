export class NVC {
    constructor(
      public firstname: string,
      public lastname: string,
      public companyname: string,
      public designation: string,
      public email: string,
      public phone: string,
      public address: string,
      public requiredService: string,
      public id?:   number) {}
  }
  