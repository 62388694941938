import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pot-luck',
  templateUrl: './pot-luck.component.html',
  styleUrls: ['./pot-luck.component.css']
})
export class PotLuckComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
