import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-eb5management',
  templateUrl: './eb5management.component.html',
  styleUrls: ['./eb5management.component.css']
})
export class Eb5managementComponent implements OnInit {
d1="EB5 ";
d4="Investment Management Programme :";
  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.meta.addTag({
      name: 'description', 
      content: 'We bring an opportunity to immigrate to the USA through investment which entitles you a Green Card for you and your family. Meet us to know the investment qualifications and other criteria.'
    });
  }

}
