
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';

import { ValuationReport } from './ValuationReport';

@Injectable({
  providedIn: 'root'
})
export class ValuationReportService {
baseUrl;
eb5s: ValuationReport[];
token;

constructor(private http: HttpClient, private authService: AuthService) { 
  this.baseUrl = authService.getUrl()
}

  store(eb5: ValuationReport): Observable<ValuationReport[]> {
    return this.http.post(`${this.baseUrl}/ValuationReport`, eb5)
      .pipe(map((res) => {
        return this.eb5s;
      }),
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error);

    // return an observable with a user friendly message
    return throwError('Error! something went wrong.');
  }
}
