export class EB5 {
    constructor(
      public fname: string,
      public lname: string,
      public email: string,
      public phone: string,
      public fDOB: string,
      public occuoation: string,
      public mStatus: string,
      public address: string,
      public requestdetail: string,
      public fdate: string,
      public ftime: string,
      public reasonImmigration: string,
      public id?:   number) {}
  }
  