import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-easybuilder',
  templateUrl: './easybuilder.component.html',
  styleUrls: ['./easybuilder.component.css']
})
export class EasybuilderComponent implements OnInit {
d1="easy";
d4="BUILDER :";
d2="It bypasses human intervention in handling some key functionalities like management of inquiry, sales and inventory, work status & reporting at various level of the construction process, which in turn, maximizes productivity and err-free operations.";
  constructor() { }

  ngOnInit() {
  }

}
