import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-easyime',
  templateUrl: './easyime.component.html',
  styleUrls: ['./easyime.component.css']
})
export class EasyimeComponent implements OnInit {

  heading1 = "Business Description :";
  p1 = " A Marketplace for Immigration: easyIME is one stop solution for Green Card medical examination by connecting the applicants to USCIS approved civil surgeons on real time basis and completing the entire process in fastest way vs standard 3 visits. The service is free for the green card applicants and our paid customers are the USCIS approved civil surgeon (doctors). By access to Immigrants and USCIS dependents, easyIME becomes unique marketplace for immigration.";
  h2 = "The Problem : ";
  p2 = "No Single Platform streamlines immigration exam process. 75% of the applicants travel double the distance and makes Three to Four visits to complete exam. They also end up paying more due to lack of information and close to 15% engage un-authorized Doctors / Lawyers. Civil Surgeons get un-screened applications and documentations, consuming their clinic time. Due to manual I693 form handling chances of error is high resulting in delaying green card.";
  h3 = "Applicants";
  po1 = "No Single Platform Service exists.";
  po2 = "75% Travel double the distance.";
  po3 = "Make Three to Four visits and take up to a week to complete this process.";
  po4 = "End up paying more due to lack of information.";
  po5 = "15% engage un-authorized Doctors / Lawyers.";
  h4 = "Civil Surgeon";
  po6 = "Civil Surgeons get un-screened applications and documentations, consuming their clinic time.";
  po7 = "Manual I-693 Form completion, chances of error.";
  po8 = "Unexpected queues at clinic.";
  po9 = "Language issues.";
  h5 = "Solution : ";
  d1 = "Quick Easy & Convenient";
  d2 = "A FREE ONLINE SYSTEM which helps Green Card Applicants to: Find nearest USCIS approved Civil Surgeons & Immigration Attorneys. With real time doctor availability and price comparisons appointment is scheduled under 2 mins. Thorough screening and electronic form handling enables completing exam in fastest manner.";

  d3 = "Referal System";
  d4 = "Find nearest USCIS approved Civil Surgeon & Immigration Attorneys.";
  d5 = "Compare Best Prices.";
  d6 = "Online Schedular.";
  d7 = "Process effeciency has Potenctial to save USCIS 135K hours annually!.";
  d8 = "Single Window Visit System.";
  d9 = "Quick and Accurate I-693 completion.";
  d10 = "Markets :";
  d11 = " 600k green card are issued annually by USCIS for applicants based in USA. For every green card applicant it is mandatory to complete IMMIGRATION MEDICAL EXAM. With avg cost of exam at $400 target market annually is $240MM";

  d12 = "Market Share Projections:";
  d13 = "Revenue Model :";

  d14 = "1) Civil Surgeons are charged $30-$50 per applicant for using easyIME services.";

  d15 = "2) For premium easyIME services 20% of the gross revenue charged.";


  d16 = "Current Status/Milestone:";

  d17 = "Financial Projections (Unaudited):";
  d18 = "3% Market Acquisition- Annually providing service to 18k applicants";

  d19 = "10% of approved USCIS civil surgeons under active contracts";

  d20 = "100% scale up ready with established infrastructure, technology, operations and processes";

  d21 = "Established marketing methods";

  d22 = "Investment Needed 500k- 1MM:";

  d23 = "60% of the total immigration medical exam applicants are based in metro areas of CA,NY, FL, TX and IL";

  d24 = "Funds will be used for different marketing channels such as digital marketing, print media, mass media to Target Applicants from High concentration areas.";

  d25 = "For Partnerships & Event Sponsorships : AILA, Fed BAR, American Bar and State Bar";

  d26 = "For sales and enhancing already developed technology";






  constructor() { }

  ngOnInit() {

  }

}
