import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Component, OnInit, Output, Input } from '@angular/core';

import { EB5 } from './EB5';
import { EB5Service } from './EB5.service';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-accordianform',
  templateUrl: './accordianform.component.html',
  styleUrls: ['./accordianform.component.css']
})
export class AccordianformComponent implements OnInit {
  eb5s: EB5[];
  error = '';
  success = '';
  alert_box: boolean = false;
  downloadStatus: boolean = false;
  @Input() isLoading = true;
  today: number;
  maxdate: Date;
  eighteenyears: number = 568080000000;
  validDob: boolean;
  validpDate: boolean;
  eb5 = new EB5('', '', '', '', '', '', '', '','','','','');
  myRecaptcha: boolean;
  value: Date= new Date();
  intialdate:Date = new Date();
  dobValue: Date;
  dobFormat = 'dd-MM-yyyy';
  pDateTimeFormat = 'dd-MM-yyyy HH:mm';
  constructor(private eb5Service: EB5Service, private http: HttpClient, private datepipe: DatePipe) {
  }

  ngOnInit() {
    this.validDob = false;
    this.validpDate = false;
    this.today = Date.now();
    this.maxdate = new Date(this.today - this.eighteenyears);
  }

  addeb5(f: NgForm) {
    this.alert_box = f.invalid;
    this.resetErrors();

    for (var key in f.controls) {
      f.controls[key].markAsTouched();
    }

    if (!f.invalid) {
      this.eb5.fDOB = this.datepipe.transform(this.dobValue, 'yyyy-MM-dd');
      this.eb5.fdate = this.datepipe.transform(this.value, 'yyyy-MM-dd');
      this.eb5.ftime = this.datepipe.transform(this.value, 'hh:mm');
      this.loadingStart();

      this.eb5Service.store(this.eb5)
        .subscribe(
          (res: EB5[]) => {
            // Update the list of eb5s
            this.eb5s = res;
            this.success = 'Thank You For Contacting Us.';
            f.reset();
          },
          (err) => this.error = err
        );

    }

  }

  loadingStart() {

    this.downloadStatus = true
  }

  loadingStop() {
    this.downloadStatus = false
  }

  private resetErrors() {
    this.error = '';
  }

  onDateChange(){
    const d1 = new Date(this.value).getTime();
    if (d1 < this.today + 60000) {
      this.validpDate = true;
    } else {
      this.validpDate = false;
    }
  }

  checkValidDob(){
    const d1 = new Date(this.dobValue).getTime();
    if (d1 >= (Date.now() - this.eighteenyears)) {
      this.validDob = true;
    } else {
      this.validDob = false;
    }
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA');
  }
  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)){
      return false;
    }
    return true;
  }
}
