import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chrismas',
  templateUrl: './chrismas.component.html',
  styleUrls: ['./chrismas.component.css']
})
export class ChrismasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
