import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-bpo-csr',
  templateUrl: './bpo-csr.component.html',
  styleUrls: ['./bpo-csr.component.css']
})
export class BpoCsrComponent implements OnInit {
  title = 'ESMSYS Offerings in BPO KPO Industry';
  d1 = "Grow more with our ";
  d6 = "BPO AND CSR";
  d7 = "Support :";
  d2 = "Outsourcing your data, intelligence and customer support services in hospitality, health, US immigration and US law with esmsys leaves room for your growth, convenience and savings. We are BPO partners to the leading SaaS and Real Estate Project Providers such as  easyIME - USCIS Civil Surgeons &amp; authorized US Attorneys and Lawyers, Ashcroft Sullivan EB5 Project, JaherNotice and many more.";
  d3 = "esmsys delivers speed and accuracy to xtraCHEF which automates manual, time-consuming, and error-prone tasks typically performed by chefs, restaurant managers, bookkeepers, and accountants.";
  d4 = "Our CSR services to USCIS authorized Civil Surgeons, Attorneys and Lawyers enables a quick, easy and convenient environment to Green Card Immigration aspirants in USA through easyIME";
  d5 = "Back in India, our CSR speak in language local to Gujarat, enabling the clientele of JaherNotice to understand and avail the complicated Government transactional services within the comfort of their home or office.";
  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);

    this.meta.addTag({
      name: 'description',
      content: 'We are BPO partners to the leading SaaS and Real Estate Project Providers like easyIME - USCIS Civil Surgeons; Authorized US Attorneys and Lawyers - Ashcroft Sullivan EB5 Project, Jahernotice and more'
    });
  }

}
