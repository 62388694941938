import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.css']
})
export class FinanceComponent implements OnInit {
  title = 'ESMSYS Offerings in Finance Industry';

  d1 = "Overview : ";
  d2 = "Starting year 2010, esmsys added a new subsidiary company into their company organization chart allowing it to do business as a Private Equity firm.";

  d3 = "Under this Private Equity firm, esmsys’s primary concentration will be in the Indian Real Estate market targeting new constructions and land acquisitions with a balance-of-mix portfolio of both residential and commercial properties.";

  d4 = "We are encouraging individual and business owners with net income of $100K and over which makes us unique from many conventional private equity investment firms. As a relatively young firm we aim to be more agile, more responsive and more innovative than our peers. We encourage an entrepreneurial approach and employ some of the brightest fund management team. ESMSYS is an investment firm without the constraints of a traditional fund-based business model.";

  d5 = "Our passion is to spread the wealth around by passing total return on our fund performance equally among all investors regardless of their investment amount.";

  d6 = "We maintain eagle eye on our fund performance with local foot print in the market.";

  d7 = "Our low management cost, local bargaining power, and local political relations, allow us to structure deals that are mutually beneficial to all constituents.";


  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);

    this.meta.addTag({
      name: 'description',
      content: 'ESMSYS is an investment firm without the constraints of a traditional fund-based business model. We encourage an entrepreneurial approach and employ some of the brightest fund management team.'
    });
  }

}
