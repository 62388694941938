import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-board-of-directors',
  templateUrl: './board-of-directors.component.html',
  styleUrls: ['./board-of-directors.component.css']
})
export class BoardOfDirectorsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
