import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cultural-day',
  templateUrl: './cultural-day.component.html',
  styleUrls: ['./cultural-day.component.css']
})
export class CulturalDayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
