import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diwali',
  templateUrl: './diwali.component.html',
  styleUrls: ['./diwali.component.css']
})
export class DiwaliComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
