import { Component, OnInit, ViewChild } from '@angular/core';

import { Car } from './car';
import { CarService } from './car.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
cars: Car[];
  error = '';
  success = '';
  myRecaptcha: boolean;
  car = new Car('', '', '', '', 0);

  constructor(private carService: CarService) {
  }

  ngOnInit() {
  }

  addCar(f) {
    this.resetErrors();


    for (let key in f.controls) {
      f.controls[key].markAsTouched();
    }

    if (!f.invalid) {
      this.carService.store(this.car)
      .subscribe(
        (res: Car[]) => {
          // Update the list of cars
          this.cars = res;
          // Inform the user
          this.success = 'Thank You For Contacting Us.';
          // Reset the form
          f.reset();
          window['grecaptcha'].reset();
        },
        (err) => this.error = err
      );
      debugger;
    }
  }


  private resetErrors() {
    this.success = '';
    this.error   = '';
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA');
  }
}
