import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fitting-manufactur',
  templateUrl: './fitting-manufactur.component.html',
  styleUrls: ['./fitting-manufactur.component.css']
})
export class FittingManufacturComponent implements OnInit {
  // d1="EB5 ";
  d1="";
  d4="Quick Fitting Manufacturing Project Highlights :";
  constructor() { }

  ngOnInit() {
  }

}
