import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-easyhr',
  templateUrl: './easyhr.component.html',
  styleUrls: ['./easyhr.component.css']
})
export class EasyhrComponent implements OnInit {

  d1 = "easy";
  d4 = "HR :";
  d2 = "The Human Resource module developed by esmsys has a set of robust features and what’s more, it integrates seamlessly with other modules that will help your organization to deliver uninterrupted stream of HR functionalities. The HR module from esmsys covers all the functions required in business practice and is flexible enough to optimize your business processes by configuring to suit customer requirements individually.";


  constructor() { }

  ngOnInit() {
  }

}
