import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FinanceComponent } from './finance/finance.component';
import { RealEstateComponent } from './real-estate/real-estate.component';
import { EasyimeComponent } from './easyime/easyime.component';
import { JahernoticeComponent } from './jahernotice/jahernotice.component';

import { EasybuilderComponent } from './easybuilder/easybuilder.component';
import { EasyhrComponent } from './easyhr/easyhr.component';
import { PipefittingsComponent } from './pipefittings/pipefittings.component';
import { ThermalinsulationComponent } from './thermalinsulation/thermalinsulation.component';
import { Eb5managementComponent } from './eb5management/eb5management.component';
import { NvcServicesComponent } from './nvc-services/nvc-services.component';
import { BrandManagementComponent } from './brand-management/brand-management.component';
import { BpoCsrComponent } from './bpo-csr/bpo-csr.component';
import { CurrentOpeningsComponent } from './current-openings/current-openings.component';
import { ApplyNowComponent } from './apply-now/apply-now.component';
import { BoardOfDirectorsComponent } from './board-of-directors/board-of-directors.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeComponent } from './home/home.component';
import { TimelineComponent } from './timeline/timeline.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { WebDesignComponent } from './web-design/web-design.component';
import { MobileAppComponent } from './mobile-app/mobile-app.component';
import { P1Component } from './p1/p1.component';
import { ClientComponent } from './client/client.component';
import { VillageProjectComponent } from './village-project/village-project.component';
import { FittingManufacturComponent } from './fitting-manufactur/fitting-manufactur.component';
import { RequestOne2OneMeetingComponent } from './request-one2-one-meeting/request-one2-one-meeting.component';
import { ThankyouPageComponent } from './thankyou-page/thankyou-page.component';
import { EB5RegionalCenterComponent } from './eb5-regional-center/eb5-regional-center.component';
import { EB5InvestmentRequirementsComponent } from './eb5-investment-requirements/eb5-investment-requirements.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { EB5TrackyourCaseStatusComponent } from './eb5-trackyour-case-status/eb5-trackyour-case-status.component';
import { ItInfraComponent } from './it-infra/it-infra.component';
import { GreenCardCOVID19Component } from './green-card-covid19/green-card-covid19.component';
import { GreenCardFamilyComponent } from './green-card-family/green-card-family.component';
import { GreenCardCovid19ApplyComponent } from './green-card-covid19-apply/green-card-covid19-apply.component';
import { HomeEB5Component } from './home-eb5/home-eb5.component';
import { EasyMDAComponent } from './easy-mda/easy-mda.component';
import { EasyilaComponent } from './easyila/easyila.component';
import { ProplegitComponent } from './proplegit/proplegit.component';
import { Eb5GreenCardComponent } from './eb5-green-card/eb5-green-card.component';
import { LifestyleComponent } from './lifestyle/lifestyle.component';
import { ChrismasComponent } from './chrismas/chrismas.component';
import { GaneshChaturthiComponent } from './ganesh-chaturthi/ganesh-chaturthi.component';
import { NavratriComponent } from './navratri/navratri.component';
import { DiwaliComponent } from './diwali/diwali.component';
import { ThanksGivingDayComponent } from './thanks-giving-day/thanks-giving-day.component';
import { CulturalDayComponent } from './cultural-day/cultural-day.component';
import { PotLuckComponent } from './pot-luck/pot-luck.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { GreenCardAppointmentComponent } from './green-card-appointment/green-card-appointment.component';
import { Eb5investmentappointmentthankyouComponent } from './eb5investmentappointmentthankyou/eb5investmentappointmentthankyou.component';
import { Eb5SubmitYouComponent } from './eb5-submit-you/eb5-submit-you.component';
import { Eb5GujaratiComponent } from './eb5-gujarati/eb5-gujarati.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';





const routes: Routes = [
  { path: '', redirectTo: '/Home', pathMatch: 'full' },
  { path: 'Home', component: HomeComponent },
  
  { path: 'employment', component: P1Component },
  { path: 'Client', component: ClientComponent },
  { path: 'Finance', component: FinanceComponent },
  { path: 'RealEstate', component: RealEstateComponent },
  { path: 'Easyime', component: EasyimeComponent },
  { path: 'JaherNotice', component: JahernoticeComponent },
  { path: 'EasyBuilder', component: EasybuilderComponent },
  { path: 'EasyHR', component: EasyhrComponent },
  // { path: 'PipeFittings', component: PipefittingsComponent },
  { path: 'ThermalInsulation', component: ThermalinsulationComponent },
  { path: 'EB5', component: Eb5managementComponent },
  { path: 'NVC', component: NvcServicesComponent },
  { path: 'NVC/thank-you', component: ThankyouPageComponent },
  { path: 'Brand', component: BrandManagementComponent },
  { path: 'Brand/thank-you', component: ThankyouPageComponent },
  { path: 'BPOCSR', component: BpoCsrComponent },
  { path: 'BPOCSR/thank-you', component: ThankyouPageComponent },
  { path: 'CurrentOpenings', component: CurrentOpeningsComponent },
  { path: 'ApplyNow', component: ApplyNowComponent },
  { path: 'BOD', component: BoardOfDirectorsComponent },
  { path: 'Team', component: OurTeamComponent },
  { path: 'EB5/RegionalCenter', component: EB5RegionalCenterComponent },
  { path: 'EB5/InvestmentRequirements', component: EB5InvestmentRequirementsComponent },
  { path: 'EB5/Contact', component: RequestOne2OneMeetingComponent },
  { path: 'EB5/Contact/ThankYou', component: ThankyouPageComponent },
  { path: 'EB5/TrackyourCaseStatusComponent', component: EB5TrackyourCaseStatusComponent },
  { path: 'Contact', component: ContactUsComponent },
  { path: 'it-infrastructure', component: ItInfraComponent },
  { path: 'it-infrastructure/thank-you', component: ThankyouPageComponent },
  { path: 'WebDevelopment', component: WebDevelopmentComponent },
  { path: 'WebDevelopment/thank-you', component: ThankyouPageComponent },
  { path: 'WebDesign', component: WebDesignComponent },
  { path: 'WebDesign/thank-you', component: ThankyouPageComponent },
  { path: 'MobileApp', component: MobileAppComponent },
  { path: 'MobileApp/thank-you', component: ThankyouPageComponent },
  { path: 'Timeline', component: TimelineComponent },
  { path: 'SportsVillage', component: VillageProjectComponent },
  { path: 'QuickFitting', component: FittingManufacturComponent },
  { path: 'Disclaimer', component: DisclaimerComponent },
  { path: 'us-pr', component: GreenCardFamilyComponent },
  { path: 'us-pr/apply/:id', component: GreenCardCovid19ApplyComponent },
  { path: 'us-green-card-c19', component: GreenCardCOVID19Component },
  { path: 'us-green-card-c19/apply/:id', component: GreenCardCovid19ApplyComponent },
  { path: 'us-green-card-c19/Thankyou', component: GreenCardCovid19ApplyComponent },
  { path: 'easy-mda', component: EasyMDAComponent },
  { path: 'proplegit', component: ProplegitComponent },
  { path: 'easyila', component: EasyilaComponent },
  { path: 'EB5/eb5greencard', component: Eb5GreenCardComponent },
  { path: 'lifestyle', component: LifestyleComponent },
  { path: 'chrismas', component: ChrismasComponent },
  { path: 'navratri', component: NavratriComponent },
  { path: 'diwali', component: DiwaliComponent },
  { path: 'ganesh-chaturthi', component: GaneshChaturthiComponent },
  { path: 'thanks-giving-day', component: ThanksGivingDayComponent },
  { path: 'cultural-day', component: CulturalDayComponent },
  { path: 'pot-luck', component: PotLuckComponent },
  { path: 'EB5/green-card-appointment', component: GreenCardAppointmentComponent },
  { path: 'EB5/eb5investmentappointmentthankyou', component: Eb5investmentappointmentthankyouComponent },
  { path: 'EB5/eb5-submit-you', component: Eb5SubmitYouComponent },
  { path: 'EB5/eb5-gujarati', component: Eb5GujaratiComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'thankyou', component: ThankyouComponent },
  { path: 'HomeEB5', component: HomeEB5Component },
 
  { path: 'GC19', redirectTo: 'us-green-card-c19', pathMatch: 'full' },
  { path: '**', component: HomeComponent },
 
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
